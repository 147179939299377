import React from 'react';

const VocableForage: React.FC = () => {
  return (
    <div>
      <h1>Vocable Forage</h1>
      <p>Description of Vocable Forage project...</p>
    </div>
  );
};

export default VocableForage;